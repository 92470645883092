.App {
  text-align: center;
}

.App-logo {
  height: 50px;
  pointer-events: none;
  vertical-align: middle;
  margin-top: 16px;
}

.bw-logo {
  height: 30px;
  pointer-events: none;
  vertical-align: middle;
}

/* Targeting menu items within dropdowns specifically */
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-item-selected {
  background-color: transparent !important;
  /* Removes the background color change */
  border-color: transparent !important;
  /* Optional: Removes any border color change */
}

.dropdown-trigger {
  cursor: pointer;
  color: black;
  font-size: medium;
}

.Content-logo {
  height: 40vmin;
  pointer-events: none;
  vertical-align: middle;
  margin-top: 10vmin;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.My-header {
  position: 'sticky';
  top: 0;
  z-index: 1;
  width: '100%';
  display: 'flex';
  align-items: 'center';
  background-color: 'white';
  justify-content: 'flex-start';
}

/* @media (min-width: 768px) {
  .My-header {
    justify-content: center;
    background-color: 'black';
  }
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}